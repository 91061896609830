//STATIC
import states from '../../../static/states';

//UTILS
import getNestedObject from '../getNestedObject';

//API
import api from '../../helpers/api';

import ReactGA from 'react-ga';

import moment from 'moment';
import {
  lineBadgePercent,
  lineCalendarEmpty,
  lineCalendarFull,
  lineCheck,
  lineCoinsDown,
  lineCoinsUp,
  lineEdit,
  lineFileContract,
  lineFileDoublePlus,
  lineFileDownload,
  lineFileInvoiceDollar,
  lineFolders,
  lineHistory,
  lineMobile,
  linePhone,
  linePlusCircle,
  lineShoppingCart,
  lineSync,
  lineTrash,
  lineUsers,
  lineUserSlash,
  linePageBreak,
  lineReceipt,
  lineFileInvoice,
  lineTimes,
  lineTruckLoading,
  lineCheckCircle,
  lineOutcome,
  lineIncome,
  lineUndo,
  lineBoxUSD,
  lineFunnelDollar,
} from '@l_ine/light-svg-icons';
import {
  lineBarcodeRead,
  lineDirection,
  lineExchange,
  lineUser,
} from '@l_ine/regular-svg-icons';
import {
  lineCalendarMinus,
  lineCalendarTimes,
  lineCar,
  lineEye,
  lineFrontBigTruck,
  lineFrontBus,
  lineFrontTruck,
  lineMotorcycle,
} from '@l_ine/solid-svg-icons';
import { paddingLeft } from '@l_ine/utils';
import formatStringByPattern from 'format-string-by-pattern';
import { Profile } from '../../redux/auth/types';
import useExtensaoStore from '../../store/extensao/useExtensao';
export const ValidateExtension = (codigo_cidade: number) => {
  if (!codigo_cidade) {
    return false;
  }

  const sigla = String(states[codigo_cidade.toString().substring(0, 2)].label);

  const estadosComExtensao = [
    'RO',
    'RN',
    'SE',
    'PE',
    'AM',
    'SP',
    'ES',
    'GO',
    'PA',
    'AL',
    'PB',
    'MA',
    'PI',
    'MG',
  ];

  return estadosComExtensao.includes(sigla);
};

//VERIFICA SE O OBJETO É VAZIO
export const isEmptyObject = (obj: any) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }

  return true;
};

export const removeAt = (array: any[], position: number) => {
  return array.filter((_, index) => index != position);
};

export const retira_acentos = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

//Realiza o download de um arquivo
export const downloadContent = (content: Blob, name: string) => {
  if (!content) {
    throw new Error(`Erro ao tentar baixar o arquivo ${name}`);
  }

  const a = document.createElement('a');
  a.href = URL.createObjectURL(content);
  a.download = String(name).replaceAll('.', ' ');
  a.click();
  a.remove();
};

export const sendToExtension = (action: string, data: any, service?: any, callback?: (resp: any) => void) => {
  if (!action || !data) {
    throw new Error('Nenhuma action ou nenhum dado para enviar para a extensão!');
  }

  const extension: string = useExtensaoStore.getState().id || 'nghepobjngefnfbgljafnkmcadfiocpf';
  
  if (typeof chrome != 'undefined' && !!window.chrome) {
    chrome?.runtime?.sendMessage(extension, {
      action: action,
      data: data,
      service: service,
    }, callback);
  } else {
    const extensionRequesEvent = new CustomEvent('extensionRequest', {
      detail: {
        action: action,
        data: data,
        service: service,
        callback
      },
    });
    document.dispatchEvent(extensionRequesEvent);
  }
};

export const openExtensionInstaller = () => {
  if (navigator.userAgent.indexOf('Edg') != -1) {
    window.open(
      'https://microsoftedge.microsoft.com/addons/detail/icfc/njjnpljoblkhhomecbcgkmcikedafgci',
      '_blank'
    );
  } else if (
    //@ts-ignore
    (!!window.opr && !!opr.addons) ||
    //@ts-ignore
    !!window.opera ||
    navigator.userAgent.indexOf(' OPR/') >= 0
  ) {
    window.open('https://addons.opera.com/pt-br/extensions/details/icfc/', '_blank');
  } else if (navigator.userAgent.indexOf('Firefox') != -1) {
    window.open('/static/icfc.xpi', '_blank');
  } else {
    window.open(
      'https://chrome.google.com/webstore/detail/icfc/nghepobjngefnfbgljafnkmcadfiocpf',
      '_blank'
    );
  }
};

//Junta todas as horas para deixar um horario só (Extende o card do turno)
export const juntarHoras = (primeiro: any, segundo: any) => {
  let ret = {
    hora_inicial: '',
    hora_final: '',
    data_inicial: '',
    data_final: '',
  };

  // Pega a menor hora
  if (
    moment(primeiro.hora_inicial, 'HH:mm').unix() >
    moment(segundo.hora_inicial, 'HH:mm').unix()
  ) {
    ret.hora_inicial = segundo.hora_inicial;
  } else {
    ret.hora_inicial = primeiro.hora_inicial;
  }

  // Pega a maior hora
  if (
    moment(primeiro.hora_final, 'HH:mm').unix() >
    moment(segundo.hora_final, 'HH:mm').unix()
  ) {
    ret.hora_final = primeiro.hora_final;
  } else {
    ret.hora_final = segundo.hora_final;
  }

  ret.data_inicial = primeiro.data_inicial;
  ret.data_final = primeiro.data_final;

  return ret;
};

//Se uma hora está dentro de outra (isBetween) retorna true
export const verificarSeTaDentro = (primeiroHorario: any, segundoHorario: any) => {
  //Se as datas forem diferentes retorna false pois é outro período
  if (
    primeiroHorario.data_inicial &&
    !(
      Boolean(
        moment(primeiroHorario.data_inicial.replace('Z', ''))
          .utc(true)
          .format('DD/MM/YYYY') ==
          moment(segundoHorario.data_inicial.replace('Z', ''))
            .utc(true)
            .format('DD/MM/YYYY')
      ) &&
      Boolean(
        moment(primeiroHorario.data_final?.replace('Z', ''))
          .utc(true)
          .format('DD/MM/YYYY') ==
          moment(segundoHorario.data_final?.replace('Z', ''))
            .utc(true)
            .format('DD/MM/YYYY')
      )
    )
  ) {
    return false;
  }

  //hora inicial se estiver dentro de um turno
  if (
    moment(primeiroHorario.hora_inicial, 'HH:mm').unix() <=
      moment(segundoHorario.hora_inicial, 'HH:mm').unix() &&
    moment(segundoHorario.hora_inicial, 'HH:mm').unix() <=
      moment(primeiroHorario.hora_final, 'HH:mm').unix()
  ) {
    return true;
  }
  //hora inicial se estiver dentro de um turno
  if (
    moment(segundoHorario.hora_inicial, 'HH:mm').unix() <=
      moment(primeiroHorario.hora_inicial, 'HH:mm').unix() &&
    moment(primeiroHorario.hora_inicial, 'HH:mm').unix() <=
      moment(segundoHorario.hora_final, 'HH:mm').unix()
  ) {
    return true;
  }
  return false;
};

export const formatHourParse = (value: any) => {
  if (value) {
    if (!/^[0-9:]+$/.test(value)) {
      return String(value).substring(0, String(value).length - 1);
    }

    if (value.length == 1) {
      if (parseFloat(value) > 2) {
        return paddingLeft(value, 2, '0');
      }
    }

    if (value.length == 5) {
      return String(value).substring(0, 4) + '0';
    }

    return formatStringByPattern('12:00' as string, value);
  }
};

export const EscolaridadeConv = (escolaridade: number) => {
  if (escolaridade == 0) {
    return 'Não alfabetizado';
  } else if (escolaridade == 1) {
    return 'Ensino fundamental incompleto';
  } else if (escolaridade == 2) {
    return 'Ensino fundamental completo';
  } else if (escolaridade == 3) {
    return 'Ensino médio incompleto';
  } else if (escolaridade == 4) {
    return 'Ensino médio completo';
  } else if (escolaridade == 5) {
    return 'Ensino superior incompleto';
  } else if (escolaridade == 6) {
    return 'Ensino superior completo';
  } else {
    return 'Não informado';
  }
};

export const tagHotjar = (name: string) => {
  //@ts-ignore
  if (window && window.hj) {
    //@ts-ignore
    window.hj('tagRecording', [name]);
  }

  ReactGA.event({
    action: name,
    category: name,
  });

  //@ts-ignore
  if (window && window.clarity) {
    //@ts-ignore
    window.clarity('set', name, name);
  }
};

// SexoConv é o método responsável por retornar o nome do sexo baseado no seu código.
export const SexoConv = (sexo: number) => {
  if (sexo == 0) {
    return 'Masculino';
  } else if (sexo == 1) {
    return 'Feminino';
  } else {
    return 'Não Informado';
  }
};

//Retorna a cor da aula conforme a situação
export const getColor = (situacao: number) => {
  if (situacao == 0) {
    //Agendada
    return '#AFD2FF';
  } else if (situacao == 1) {
    //Realizada
    return '#D6EEBC';
  } else if (situacao == 2) {
    //Falta perdendo crédito
    return '#ffc0c0';
  } else if (situacao == 3) {
    //Aula cancelada perdendo crédito
    return '#000000';
  } else if (situacao == 4) {
    //Excluída antes das 24H matendo crédito
    return '#EF4829';
  } else if (situacao == 5) {
    //Periodo indisponivel
    return '#8B8B8B';
  } else if (situacao == 6) {
    //Capacidade Máxima
    return '#CACACA';
  } else if (situacao == 7) {
    //Exame
    return '#8AB1C2';
  } else if (situacao == 8) {
    //Falta matendo crédito
    return '#ffc0c0';
  }

  return '#AFD2FF';
};

/// Retorna o titulo da aula conforme a situação
export const getTitle = (situacao: number) => {
  switch (situacao) {
    case 0:
      //Perde o crédito do aluno
      return 'Agendada';

    case 1:
      //Perde o crédito do aluno
      return 'Realizada';

    case 2:
      //Perde o crédito do aluno
      return 'Ausente';
    case 3:
      //Perde o crédito do aluno
      return 'Excluída';

    case 4:
      //Mantém o crédito do aluno
      return 'Cancelada';

    case 5:
      return 'Período indisponível';

    case 6:
      return 'Capacidade Máxima';

    case 7:
      return 'Exame';

    case 8:
      //Mantém o crédito do aluno
      return 'Ausente';

    default:
      return 'Agendada';
  }
};

/// Retorna o titulo da aula conforme a situação
export const getTitleExame = (situacao: number) => {
  switch (situacao) {
    case 0:
      return 'Agendado';

    case 1:
      return 'Aprovado';

    case 2:
      return 'Reprovado';

    case 3:
    case 4:
      return 'Ausente';

    case 5:
    case 6:
      return 'Excluído';

    default:
      return 'Agendado';
  }
};
export const getColorExame = (situacao: number) => {
  switch (situacao) {
    case 0:
      return '#AFD2FF';

    case 1:
      return '#49BF00';

    case 2:
      return '#EF4829';

    case 3:
    case 4:
      return '#FFAB00';

    case 5:
    case 6:
      return '#000000';

    default:
      return '#AFD2FF';
  }
};

export const aulasStatus = [
  {
    key: 0,
    value: 'Agendada',
    label: 'Agendada',
  },
  {
    key: 1,
    value: 'Realizada',
    label: 'Realizada',
  },
  {
    key: 2,
    value: 'Ausente',
    label: 'Ausente',
  },
  {
    key: 3,
    value: 'Desmarcada',
    label: 'Desmarcada',
  },
  {
    key: 7,
    value: 'Exame',
    label: 'Exame',
  },
];
export const integracaoStatus = [
  {
    key: 0,
    value: 'Não integrada',
    label: 'Não integrada',
  },
  {
    key: 1,
    value: 'Pendente',
    label: 'Pendente',
  },

  {
    key: 2,
    value: 'Integrada',
    label: 'Integrada',
  },
  {
    key: 3,
    value: 'Erro integração',
    label: 'Erro integração',
  },
];

/// Retorna o cor da integracao conforme a situação
export const getIntegrationColor = (situacao: number) => {
  switch (situacao) {
    case 0:
      return '#AFD2FF';
    case 1:
      return '#FFAB00';
    case 2:
      return '#49BF00';
    case 3:
      return '#EF4829';

    default:
      return '#AFD2FF';
  }
};

/// Retorna o titulo da aula conforme a situação
export const getIntegrationTitle = (situacao: number) => {
  switch (situacao) {
    case 0:
      return 'Não integrada';

    case 1:
      return 'Pendente';

    case 2:
      return 'Integrada';

    case 3:
      return 'Erro integração';

    default:
      return 'Não integrada';
  }
};

export const getIconCategorie = (categoria: string) => {
  switch (categoria) {
    case 'A':
      return lineMotorcycle;

    case 'B':
      return lineCar;

    case 'C':
      return lineFrontTruck;

    case 'D':
      return lineFrontBus;

    case 'E':
      return lineFrontBigTruck;

    default:
      return lineCar;
  }
};

export const provedorIntegracao = [
  { value: 1, key: 1, label: 'Ibio', 'data-testid': '' },
];

export const colunasProtocolos = [
  {
    value: 1,
    key: 'Nome',
    label: 'Nome',
    'data-testid': '',
  },
  {
    value: 2,
    key: 'CPF',
    label: 'CPF',
    'data-testid': '',
  },
  {
    value: 3,
    key: 'RG',
    label: 'RG',
    'data-testid': '',
  },
  {
    value: 4,
    key: 'Renach',
    label: 'Renach',
    'data-testid': '',
  },
  {
    value: 5,
    key: 'Cidade/UF',
    label: 'Cidade/UF',
    'data-testid': '',
  },
  {
    value: 6,
    key: 'Processo',
    label: 'Processo',
    'data-testid': '',
  },
  {
    value: 7,
    key: 'Presença',
    label: 'Presença',
    'data-testid': '',
  },
  {
    value: 8,
    key: 'Ausência',
    label: 'Ausência',
    'data-testid': '',
  },
  {
    value: 9,
    key: 'Categoria',
    label: 'Categoria',
    'data-testid': '',
  },
  {
    value: 10,
    key: 'Observação',
    label: 'Observação',
    'data-testid': '',
  },
  {
    value: 11,
    key: 'Número sequencial',
    label: 'Número sequencial',
    'data-testid': '',
  },
];

export const tiposProtocolos = [
  {
    label: 'Exame psicotécnico',
    value: 1,
    key: 1,
  },
  {
    label: 'Exame médico',
    value: 2,
    key: 2,
  },
  {
    label: 'Exame teórico',
    value: 3,
    key: 3,
  },
  {
    label: 'Solicitação de LADV',
    value: 4,
    key: 4,
  },
  {
    label: 'Exame prático',
    value: 5,
    key: 5,
  },
  {
    label: 'Geral',
    value: 6,
    key: 6,
  },
];

// retorna o size do modal baseado no tamanho da tela
export const getSizeModal = () => {
  if (window) {
    if (window.innerWidth < 964) {
      return 'xxl';
    } else if (window.innerWidth < 1026) {
      return 'sm';
    } else if (window.innerWidth < 1367) {
      return 'sm';
    } else if (window.innerWidth < 1450) {
      return 'md';
    } else {
      return 'sm';
    }
  } else {
    return 'md';
  }
};

// NacionalidadeConv é o método responsável por retornar o nome da nacionalidade baseada no seu código.
export const NacionalidadeConv = (nacionalidade: number) => {
  if (nacionalidade == 0) {
    return 'Brasileiro';
  } else if (nacionalidade == 1) {
    return 'Naturalizado';
  } else if (nacionalidade == 2) {
    return 'Estrangeiro';
  } else {
    return 'Não informado';
  }
};

export const EstadoCivilConv = (estadoCivil: number) => {
  if (estadoCivil == 0) {
    return 'Solteiro(a)';
  } else if (estadoCivil == 1) {
    return 'Casado(a)';
  } else if (estadoCivil == 2) {
    return 'Divorciado(a)';
  } else if (estadoCivil == 3) {
    return 'Viúvo(a)';
  } else {
    return 'Não informado';
  }
};

export const isSupport = (profile: Profile) => {
  return profile?.email?.includes('@sbsistemas.com.br') || false;
};

export const radioYesNo = [
  {
    key: '0',
    value: '0',
    label: 'Não',
    'data-testid': ``,
  },
  {
    key: '1',
    value: '1',
    label: 'Sim',
    'data-testid': ``,
  },
];

export const contentTipoUsuario = [
  { key: '0', value: 'Atendimento', label: 'Atendimento' },
  {
    key: '2',
    value: 'Diretor de ensino',
    label: 'Diretor de ensino',
  },
  {
    key: '3',
    value: 'Financeiro',
    label: 'Financeiro',
  },
  {
    key: '4',
    value: 'Instrutor',
    label: 'Instrutor',
  },
  {
    key: '1',
    value: 'Administrador',
    label: 'Administrador',
  },
];

//Formas padrões de pagamento
export const formas = [
  {
    key: 1,
    value: 1,
    label: 'Dinheiro',
  },
  {
    key: 2,
    value: 2,
    label: 'Cheque',
  },
  {
    key: 3,
    value: 3,
    label: 'Cartão de débito',
  },
  {
    key: 4,
    value: 4,
    label: 'Cartão de crédito',
  },
  {
    key: 5,
    value: 5,
    label: 'Carnê',
  },
  {
    key: 6,
    value: 6,
    label: 'Pix',
  },
];

/// Datas padrões para os filtros
export const datas = [
  {
    key: 0,
    value: 'Hoje',
    label: 'Hoje',
  },
  {
    key: 1,
    value: 'Este mês',
    label: 'Este mês',
  },
  {
    key: 2,
    value: 'Mês passado',
    label: 'Mês passado',
  },
  {
    key: 3,
    value: 'Personalizado',
    label: 'Personalizado',
  },
];

//Quantidade de parcelas para cobrar a taxa
export const quantidadeParcelas = [
  {
    key: 0,
    value: 0,
    label: 'à Vista',
  },
  {
    key: 1,
    value: 1,
    label: 'Até 1X',
  },
  {
    key: 2,
    value: 2,
    label: 'Até 2X',
  },
  {
    key: 3,
    value: 3,
    label: 'Até 3X',
  },
  {
    key: 4,
    value: 4,
    label: 'Até 4X',
  },
  {
    key: 5,
    value: 5,
    label: 'Até 5X',
  },
  {
    key: 6,
    value: 6,
    label: 'Até 6X',
  },
  {
    key: 7,
    value: 7,
    label: 'Até 7X',
  },
  {
    key: 8,
    value: 8,
    label: 'Até 8X',
  },
  {
    key: 9,
    value: 9,
    label: 'Até 9X',
  },
  {
    key: 10,
    value: 10,
    label: 'Até 10X',
  },
  {
    key: 11,
    value: 11,
    label: 'Até 11X',
  },
  {
    key: 12,
    value: 12,
    label: 'Até 12X',
  },
];

export const diasSemana = [
  { label: 'Segunda', checked: false },
  { label: 'Terça', checked: false },
  { label: 'Quarta', checked: false },
  { label: 'Quinta', checked: false },
  { label: 'Sexta', checked: false },
  { label: 'Sábado', checked: false },
  { label: 'Domingo', checked: false },
];

export const bandeiras = [
  {
    key: 'mastercard',
    value: 'mastercard',
    label: 'Mastercard',
    icon: '/static/images/cartoes/mastercard.svg',
  },
  {
    key: 'visa',
    value: 'visa',
    label: 'Visa',
    icon: '/static/images/cartoes/visa.svg',
  },
  {
    key: 'elo',
    value: 'elo',
    label: 'Elo',
    icon: '/static/images/cartoes/elo.svg',
  },
  {
    key: 'hipercard',
    value: 'hipercard',
    label: 'Hipercard',
    icon: '/static/images/cartoes/hipercard.svg',
  },
  {
    key: 'americanExpress',
    value: 'americanExpress',
    label: 'American Express',
    icon: '/static/images/cartoes/americanExpress.svg',
  },
];

//Tempo de compensação das parcelas
export const tempoCompensacao = [
  { value: 1, key: 1, label: '1 dia' },
  { value: 2, key: 2, label: '30 dias' },
  { value: 3, key: 3, label: 'Parcelado' },
  { value: 4, key: 4, label: 'Compensar na hora' },
  { value: 5, key: 5, label: 'Não compensar automaticamente' },
  { value: 6, key: 6, label: 'Personalizado' },
];

export const changeData = (key: number) => {
  let datainicial, datafinal;
  //Hoje
  if (key == 0) {
    datainicial = moment();
    datafinal = moment();
  }
  //Este mes
  else if (key == 1) {
    datainicial = moment().startOf('month');
    datafinal = moment().endOf('month');
  }
  //Mes passado
  else if (key == 2) {
    datainicial = moment().subtract(1, 'month').startOf('month');
    datafinal = moment().subtract(1, 'month').endOf('month');
  } else {
    return;
  }

  return [datainicial, datafinal];
};

/// Ativo e inativos padrões para os filtros
export const ativosEInativos = [
  {
    label: 'Todos',
    key: '-1',
    value: '-1',
  },
  {
    label: 'Ativos',
    key: '0',
    value: '0',
  },
  {
    label: 'Inativos',
    key: '1',
    value: '1',
  },
];

/// Todas as categoria existentes
export const categorias = [
  {
    key: 'A',
    value: 'A',
    label: 'A',
  },
  {
    key: 'B',
    value: 'B',
    label: 'B',
  },
  {
    key: 'C',
    value: 'C',
    label: 'C',
  },
  {
    key: 'D',
    value: 'D',
    label: 'D',
  },
  {
    key: 'E',
    value: 'E',
    label: 'E',
  },
];

/// Array de parcelas
export const parcelas = [
  {
    key: 1,
    value: '1',
    label: '1',
  },
  {
    key: 2,
    value: '2',
    label: '2',
  },
  {
    key: 3,
    value: '3',
    label: '3',
  },
  {
    key: 4,
    value: '4',
    label: '4',
  },
  {
    key: 5,
    value: '5',
    label: '5',
  },
  {
    key: 6,
    value: '6',
    label: '6',
  },
  {
    key: 7,
    value: '7',
    label: '7',
  },
  {
    key: 8,
    value: '8',
    label: '8',
  },
  {
    key: 9,
    value: '9',
    label: '9',
  },
  {
    key: 10,
    value: '10',
    label: '10',
  },
  {
    key: 11,
    value: '11',
    label: '11',
  },
  {
    key: 12,
    value: '12',
    label: '12',
  },
];

/// Condições de pagamento
export const conditions = [
  {
    forma_pag: 'Dinheiro',
    forma_pagamento: 1,
    tipo_cobranca: 0,
    maximo_parcelas: { key: 1, value: '1', label: '1' },
    valor_desconto: 0,
    valor_acrescimo: 0,
    valor_total: 0,
    valor_parcela: 0,
    autorizada: 1,
  },
  {
    forma_pag: 'Cheque - à vista',
    forma_pagamento: 2,
    tipo_cobranca: 0,
    maximo_parcelas: { key: 1, value: '1', label: '1' },
    valor_desconto: 0,
    valor_acrescimo: 0,
    valor_total: 0,
    valor_parcela: 0,
    autorizada: 1,
  },
  {
    forma_pag: 'Cheque - a prazo',
    forma_pagamento: 2,
    tipo_cobranca: 1,
    maximo_parcelas: { key: 12, value: '12', label: '12' },
    valor_desconto: 0,
    valor_acrescimo: 0,
    valor_total: 0,
    valor_parcela: 0,
    autorizada: 1,
  },
  {
    forma_pag: 'Cartão de débito',
    forma_pagamento: 3,
    tipo_cobranca: 1,
    maximo_parcelas: { key: 1, value: '1', label: '1' },
    valor_desconto: 0,
    valor_acrescimo: 0,
    valor_total: 0,
    valor_parcela: 0,
    autorizada: 1,
  },
  {
    forma_pag: 'Cartão de crédito',
    forma_pagamento: 4,
    tipo_cobranca: 1,
    maximo_parcelas: { key: 12, value: '12', label: '12' },
    valor_desconto: 0,
    valor_acrescimo: 0,
    valor_total: 0,
    valor_parcela: 0,
    autorizada: 1,
  },
  {
    forma_pag: 'Carnê',
    forma_pagamento: 5,
    tipo_cobranca: 1,
    maximo_parcelas: { key: 12, value: '12', label: '12' },
    valor_desconto: 0,
    valor_acrescimo: 0,
    valor_total: 0,
    valor_parcela: 0,
    autorizada: 1,
  },
  {
    forma_pag: 'Pix',
    forma_pagamento: 6,
    tipo_cobranca: 0,
    maximo_parcelas: { key: 1, value: '1', label: '1' },
    valor_desconto: 0,
    valor_acrescimo: 0,
    valor_total: 0,
    valor_parcela: 0,
    autorizada: 1,
  },
];

//Horas trabalhadas na semana (turnos)
export const BusinessHours = [
  {
    turno: '1',
    businessHours: '07:00 - 12:00',
    segunda: true,
    terca: true,
    quarta: true,
    quinta: true,
    sexta: true,
    sabado: true,
    domingo: false,
  },
  {
    turno: '2',
    businessHours: '13:00 - 18:00',
    segunda: true,
    terca: true,
    quarta: true,
    quinta: true,
    sexta: true,
    sabado: true,
    domingo: false,
  },
  {
    turno: '3',
    businessHours: '19:00 - 22:00',
    segunda: true,
    terca: true,
    quarta: true,
    quinta: true,
    sexta: true,
    sabado: true,
    domingo: false,
  },
];

export const tipoHabilitacao = [
  {
    key: 0,
    value: 0,
    active: false,
    label: 'Primeira Habilitação A',
  },
  {
    key: 1,
    value: 1,
    active: false,
    label: 'Primeira Habilitação B',
  },
  {
    key: 2,
    value: 2,
    active: false,
    label: 'Primeira Habilitação AB',
  },
  {
    key: 3,
    value: 3,
    active: false,
    label: 'Adição de categoria A',
  },
  {
    key: 4,
    value: 4,
    active: false,
    label: 'Adição de categoria B',
  },
  {
    key: 5,
    value: 5,
    active: false,
    label: 'Mudança de categoria C',
  },
  {
    key: 6,
    value: 6,
    active: false,
    label: 'Mudança de categoria D',
  },
  {
    key: 7,
    value: 7,
    active: false,
    label: 'Mudança de categoria E',
  },
  {
    key: 8,
    value: 8,
    active: false,
    label: 'Mudança + Adição AC',
  },
  {
    key: 9,
    value: 9,
    active: false,
    label: 'Mudança + Adição AD',
  },
  {
    key: 10,
    value: 10,
    active: false,
    label: 'Mudança + Adição AE',
  },
  {
    key: 11,
    value: 11,
    active: false,
    label: 'Renovação de CNH',
  },
  {
    key: 12,
    value: 12,
    active: false,
    label: 'Reabilitação',
  },
  {
    key: 13,
    value: 13,
    active: false,
    label: 'Reciclagem',
  },
  {
    key: 14,
    value: 14,
    active: false,
    label: 'Treinamentos Habilitados',
  },
  {
    key: 15,
    value: 15,
    active: false,
    label: 'Outros',
  },
];

export const tipoServicos = [
  {
    codigo: 3,
    nome: 'Aula de Legislação',
  },
  {
    codigo: 4,
    nome: 'Aula de Legislação Extra',
  },
  {
    codigo: 8,
    nome: 'Aula Prática',
    categoria: true,
  },
  {
    codigo: 9,
    nome: 'Aula Prática Extra',
    categoria: true,
  },
  {
    codigo: 6,
    nome: 'Aula Simulador',
  },
  {
    codigo: 11,
    nome: 'Aluguel Veículo Exame Prático',
    categoria: true,
  },

  {
    codigo: 12,
    categoria: true,
    nome: 'Aluguel Veículo Aula Prática',
  },
  {
    codigo: 13,
    nome: 'Outros Serviços CFC',
  },
  {
    codigo: 14,
    nome: 'Outras Taxas Detran',
  },
  {
    codigo: 2,
    nome: 'Taxa Avaliação Psicológica',
  },
  {
    codigo: 7,
    nome: 'Taxa Emissão LADV',
  },
  {
    codigo: 5,
    nome: 'Taxa Exame Legislação',
  },
  {
    codigo: 1,
    nome: 'Taxa Exame Médico',
  },
  {
    codigo: 10,
    nome: 'Taxa Exame Prático',
  },
  {
    codigo: 0,
    nome: 'Taxa Inscrição Serviço CNH',
  },
];

export const camelize = (str: string | boolean) => {
  if (typeof str == 'string') {
    return str.replace(/\W+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
  } else {
    return false;
  }
};

export const randomString = (length = 16) => {
  var str = '';
  for (var i = 0; i < length; i++) {
    str += 'x';
  }
  return str.replace(/x/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

//Recebe a forma de pagamento e um tipo, retorna se é as forma de pagamento escritas e se é prazo ou avista
export const getFormaPagamento = (formaPagamento: number, tipo?: number) => {
  if (formaPagamento == 1) {
    return 'Dinheiro';
  } else if (formaPagamento == 2 && tipo == 0) {
    return 'Cheque - à vista';
  } else if (formaPagamento == 2 && tipo == 1) {
    return 'Cheque - a prazo';
  } else if (formaPagamento == 2) {
    return 'Cheque';
  } else if (formaPagamento == 3) {
    return 'Cartão de débito';
  } else if (formaPagamento == 4) {
    return 'Cartão de crédito';
  } else if (formaPagamento == 5) {
    return 'Carnê';
  } else if (formaPagamento == 6) {
    return 'Pix';
  } else if (formaPagamento == 7) {
    return 'Mercado Pago';
  } else if (formaPagamento == 8) {
    return 'PicPay';
  } else if (formaPagamento == 9) {
    return 'Conpay';
  } else if (formaPagamento == 10) {
    return 'Boleto';
  } else {
    return 'Desconhecido';
  }
};

//Recebe o tipo e transforma em objeto para o campo usar correto
export const getTipoUsuario = (tipo: number) => {
  switch (tipo) {
    case 0:
      return {
        key: tipo.toString(),
        value: 'Atendimento',
        label: 'Atendimento',
      };

    case 1:
      return {
        key: tipo.toString(),
        value: 'Administrador',
        label: 'Administrador',
      };

    case 2:
      return {
        key: tipo.toString(),
        value: 'Diretor de ensino',
        label: 'Diretor de ensino',
      };

    case 3:
      return {
        key: tipo.toString(),
        value: 'Financeiro',
        label: 'Financeiro',
      };

    case 4:
      return {
        key: tipo.toString(),
        value: 'Instrutor',
        label: 'Instrutor',
      };

    default:
      return null;
  }
};

//Retorna o dia da semana baseado no numero passado
export const getDiaDaSemana = (index: number) => {
  if (index === 0) {
    return 'Segunda';
  } else if (index === 1) {
    return 'Terça';
  } else if (index === 2) {
    return 'Quarta';
  } else if (index === 3) {
    return 'Quinta';
  } else if (index === 4) {
    return 'Sexta';
  } else if (index === 5) {
    return 'Sábado';
  } else if (index === 6) {
    return 'Domingo';
  } else {
    return 'Desconhecido';
  }
};

//Retornar o Processo passando o tipo de habilitação
export const getTipo = (tipo: number) => {
  if (tipo == 0) {
    return 'Primeira Habilitação A';
  } else if (tipo == 1) {
    return 'Primeira Habilitação B';
  } else if (tipo == 2) {
    return 'Primeira Habilitação AB';
  } else if (tipo == 3) {
    return 'Adição de categoria A';
  } else if (tipo == 4) {
    return 'Adição de categoria B';
  } else if (tipo == 5) {
    return 'Mudança de categoria C';
  } else if (tipo == 6) {
    return 'Mudança de categoria D';
  } else if (tipo == 7) {
    return 'Mudança de categoria E';
  } else if (tipo == 8) {
    return 'Mudança + Adição AC';
  } else if (tipo == 9) {
    return 'Mudança + Adição AD';
  } else if (tipo == 10) {
    return 'Mudança + Adição AE';
  } else if (tipo == 11) {
    return 'Renovação de CNH';
  } else if (tipo == 12) {
    return 'Reabilitação';
  } else if (tipo == 13) {
    return 'Reciclagem';
  } else if (tipo == 14) {
    return 'Treinamentos Habilitados';
  } else if (tipo == 15) {
    return 'Outros';
  } else {
    return 'Desconhecido';
  }
};

export function parseDate(date: string) {
  if (date) {
    var year, month, day, milliseconds;

    // ISO 8601 and RFC RFC3339 and W3C Pattern yyyy-mm-dd
    if (/^[0-9]{4}[-]{1}[0-9]{2}[-]{1}[0-9]{2}/i.test(date)) {
      var dateParts = date.substring(0, 10).split('-');
      year = parseInt(dateParts[0]);
      month = parseInt(dateParts[1]);
      day = parseInt(dateParts[2]);
      return new Date(year, month - 1, day);
    }

    // Brazilian Pattern dd/mm/yyyy
    if (/^[0-9]{2}[/]{1}[0-9]{2}[/]{1}[0-9]{4}/i.test(date)) {
      var dateParts = date.substring(0, 10).split('/');
      day = parseInt(dateParts[0]);
      month = parseInt(dateParts[1]);
      year = parseInt(dateParts[2]);
      return new Date(year, month - 1, day);
    }

    // Milliseconds of date
    if (/^[0-9]{10,14}$/i.test(date)) {
      milliseconds = parseInt(date);
      return new Date(milliseconds);
    }

    // RFC2822 and RFC1123 Pattern dd MM yyyy
    if (/^[0-9]{2}[ ]{1}[a-z]{3}[ ]{1}[0-9]{4}/i.test(date)) {
      date = date.substring(0, 11);
      return new Date(date);
    }

    // RFC 2822 and RFC1123 with day of week Pattern dow, dd MM yyyy
    if (/^[a-z]{3}[,]{1}[ ]{1}[0-9]{2}[ ]{1}[a-z]{3}[ ]{1}[0-9]{4}/i.test(date)) {
      date = date.substring(5, 17);
      return new Date(date);
    }
  }

  return null;
}

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const dataEnvioPendenciaFinanceira = [
  {
    value: 3,
    key: 3,
    label: '3 dias antes do vencimento',
    'data-testid': '',
  },
  {
    value: 1,
    key: 1,
    label: '1 dia após o vencimento',
    'data-testid': '',
  },
  {
    value: 30,
    key: 30,
    label: '30 dias após o vencimento',
    'data-testid': '',
  },
];

export const dataEnvioCNH = [
  {
    value: 30,
    key: 30,
    label: '30 dias antes do vencimento',
    'data-testid': '',
  },
  {
    value: 3,
    key: 3,
    label: '3 dias antes do vencimento',
    'data-testid': '',
  },
  {
    value: 1,
    key: 1,
    label: '1 dia antes do vencimento',
    'data-testid': '',
  },
];

export const permissionsStatic = {
  alunos: [
    {
      icon: lineUsers,
      description: 'Alunos',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: linePlusCircle,
      description: 'Cadastrar',
      isActive: false,
      slug: 'alunos-cadastrar',
    },
    {
      icon: lineEdit,
      description: 'Editar',
      isActive: false,
      slug: 'alunos-editar',
    },
    {
      icon: lineTrash,
      description: 'Excluir',
      isActive: false,
      slug: 'alunos-excluir',
    },
  ],
  vendas: [
    {
      icon: lineShoppingCart,
      description: 'Vendas',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: linePlusCircle,
      description: 'Cadastrar',
      isActive: false,
      slug: 'vendas-cadastrar',
    },
    {
      icon: lineHistory,
      description: 'Alterar datas',
      isActive: false,
      slug: 'vendas-alterar-datas',
    },
    {
      icon: lineUndo,
      description: 'Alterar datas das parcelas',
      isActive: false,
      slug: 'vendas-alterar-datas-parcelas',
    },
    {
      icon: lineBoxUSD,
      description: 'Alterar valores da venda de processo',
      isActive: false,
      slug: 'vendas-alterar-valores',
    },
    {
      icon: lineFileInvoiceDollar,
      description: 'Alterar valores da venda de serviços',
      isActive: false,
      slug: 'vendas-alterar-valores-servico',
    },
    {
      icon: lineOutcome,
      description: 'Desconto',
      isActive: false,
      slug: 'vendas-desconto',
    },
    {
      icon: lineIncome,
      description: 'Acréscimo',
      isActive: false,
      slug: 'vendas-acrescimo',
    },
    {
      icon: lineTruckLoading,
      description: 'Alterar serviços',
      isActive: false,
      slug: 'vendas-alterar-servicos',
    },
    {
      icon: lineTrash,
      description: 'Excluir',
      isActive: false,
      slug: 'vendas-excluir',
    },
    {
      icon: lineEye,
      description: 'Visualizar de todos os usuários',
      isActive: false,
      slug: 'vendas-visualizar-todos-usuarios',
    },
  ],
  parcelas: [
    {
      icon: lineBadgePercent,
      description: 'Parcelas',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: lineFileDownload,
      description: 'Pagar',
      isActive: false,
      slug: 'parcelas-pagar',
    },
    {
      icon: lineEdit,
      description: 'Alterar valores',
      isActive: false,
      slug: 'parcelas-alterar-valores',
    },
    {
      icon: lineHistory,
      description: 'Alterar datas',
      isActive: false,
      slug: 'parcelas-alterar-datas',
    },
    {
      icon: linePageBreak,
      description: 'Reparcelar',
      isActive: false,
      slug: 'parcelas-reparcelar',
    },
    {
      icon: lineTrash,
      description: 'Excluir',
      isActive: false,
      slug: 'parcelas-excluir',
    },
    {
      icon: lineSync,
      description: 'Reabrir',
      isActive: false,
      slug: 'parcelas-reabrir-parcela',
    },
  ],
  oportunidades: [
    {
      icon: lineFunnelDollar,
      description: 'Oportunidades',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: lineEye,
      description: 'Visualizar',
      isActive: false,
      slug: 'oportunidades-visualizar',
    },
  ],
  agendaAluno: [
    {
      icon: lineCalendarFull,
      description: 'Agenda do Aluno',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: lineCalendarEmpty,
      description: 'Realizar agendamento',
      isActive: false,
      slug: 'agenda-aluno-realizar-agendamento',
    },
    {
      icon: lineCalendarTimes,
      description: 'Agendamento pendência financeira',
      isActive: false,
      slug: 'agenda-aluno-permite-agendar-com-pendencia',
    },
    {
      icon: lineCalendarMinus,
      description: 'Agendamento período indisponível',
      isActive: false,
      slug: 'agenda-aluno-agendamento-com-periodo-indisponivel',
    },
    {
      icon: lineCheck,
      description: 'Marcar presença',
      isActive: false,
      slug: 'agenda-aluno-marcar-presenca',
    },
    {
      icon: lineUserSlash,
      description: 'Marcar ausência',
      isActive: false,
      slug: 'agenda-aluno-marcar-ausencia',
    },
    {
      icon: lineSync,
      description: 'Reabrir aula',
      isActive: false,
      slug: 'agenda-aluno-reabrir-aula',
    },
    {
      icon: lineTrash,
      description: 'Desmarcar aula',
      isActive: false,
      slug: 'agenda-aluno-desmarcar-aula',
    },
  ],
  agendaInstrutor: [
    {
      icon: lineCalendarFull,
      description: 'Agenda do Instrutor',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: lineCalendarEmpty,
      description: 'Realizar agendamento',
      isActive: false,
      slug: 'agenda-instrutor-realizar-agendamento',
    },
    {
      icon: lineCalendarTimes,
      description: 'Agendamento pendência financeira',
      isActive: false,
      slug: 'agenda-instrutor-permite-agendar-com-pendencia',
    },
    {
      icon: lineCalendarMinus,
      description: 'Agendamento período indisponível',
      isActive: false,
      slug: 'agenda-instrutor-agendamento-com-periodo-indisponivel',
    },
    {
      icon: lineCheck,
      description: 'Marcar presença',
      isActive: false,
      slug: 'agenda-instrutor-marcar-presenca',
    },
    {
      icon: lineUserSlash,
      description: 'Marcar ausência',
      isActive: false,
      slug: 'agenda-instrutor-marcar-ausencia',
    },
    {
      icon: lineSync,
      description: 'Reabrir aula',
      isActive: false,
      slug: 'agenda-instrutor-reabrir-aula',
    },
    {
      icon: lineTrash,
      description: 'Desmarcar aula',
      isActive: false,
      slug: 'agenda-instrutor-desmarcar-aula',
    },
  ],
  examePratico: [
    {
      icon: lineDirection,
      description: 'Exame prático',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: linePlusCircle,
      description: 'Cadastrar',
      isActive: false,
      slug: 'exame-pratico-cadastrar',
    },
    {
      icon: lineUser,
      description: 'Exibir todos os alunos',
      isActive: false,
      slug: 'exame-pratico-exibir-todos-alunos',
    },
    {
      icon: lineCheckCircle,
      description: 'Marcar como concluído',
      isActive: false,
      slug: 'exame-pratico-marcar-como-concluido',
    },
    {
      icon: lineSync,
      description: 'Reabrir exame',
      isActive: false,
      slug: 'exame-pratico-reabrir-exame',
    },
    {
      icon: lineEdit,
      description: 'Editar',
      isActive: false,
      slug: 'exame-pratico-editar',
    },
    {
      icon: lineTrash,
      description: 'Excluir',
      isActive: false,
      slug: 'exame-pratico-excluir',
    },
    {
      icon: lineCheck,
      description: 'Marcar aprovação',
      isActive: false,
      slug: 'exame-pratico-marcar-aprovacao',
    },
    {
      icon: lineTimes,
      description: 'Marcar reprovação',
      isActive: false,
      slug: 'exame-pratico-marcar-reprovacao',
    },
    {
      icon: lineSync,
      description: 'Reabrir status',
      isActive: false,
      slug: 'exame-pratico-reabrir-status',
    },
    {
      icon: lineUserSlash,
      description: 'Marcar ausência',
      isActive: false,
      slug: 'exame-pratico-marcar-ausencia',
    },
  ],
  protocolos: [
    {
      icon: lineFolders,
      description: 'Protocolos',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: linePlusCircle,
      description: 'Gerar',
      isActive: false,
      slug: 'protocolos-gerar',
    },
    {
      icon: lineEdit,
      description: 'Editar',
      isActive: false,
      slug: 'protocolos-editar',
    },
    {
      icon: lineTrash,
      description: 'Excluir',
      isActive: false,
      slug: 'protocolos-excluir',
    },
  ],
  periodoIndisponivel: [
    {
      icon: lineUserSlash,
      description: 'Período indisponíveis',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: linePlusCircle,
      description: 'Cadastrar',
      isActive: false,
      slug: 'periodo-indisponivel-cadastrar',
    },
    {
      icon: lineEdit,
      description: 'Editar',
      isActive: false,
      slug: 'periodo-indisponivel-editar',
    },
    {
      icon: lineTrash,
      description: 'Excluir',
      isActive: false,
      slug: 'periodo-indisponivel-excluir',
    },
  ],
  extrato: [
    {
      icon: lineFileInvoiceDollar,
      description: 'Extrato',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: lineSync,
      description: 'Reabrir parcela',
      isActive: false,
      slug: 'extrato-reabrir-parcela',
    },
    {
      icon: lineReceipt,
      description: 'Visualizar extrato',
      isActive: false,
      slug: 'extrato-visualizar',
    },
    // {
    //   icon: linePlusCircle,
    //   description: 'Cadastrar despesa',
    //   isActive: false,
    //   slug: 'extrato-cadastrar-despesa',
    // },
    {
      icon: lineTrash,
      description: 'Excluir despesa',
      isActive: false,
      slug: 'extrato-excluir-despsesa',
    },
    {
      icon: lineEye,
      description: 'Visualizar de todos os usuários',
      isActive: false,
      slug: 'extrato-visualizar-todos-usuarios',
    },
  ],
  receitas: [
    {
      icon: lineCoinsUp,
      description: 'Receitas',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: linePlusCircle,
      description: 'Cadastrar',
      isActive: false,
      slug: 'receitas-cadastrar',
    },
    {
      icon: lineEdit,
      description: 'Editar',
      isActive: false,
      slug: 'receitas-editar',
    },
    {
      icon: lineCheck,
      description: 'Marcar como pago',
      isActive: false,
      slug: 'receitas-marcar-como-pago',
    },
    {
      icon: lineHistory,
      description: 'Alterar datas',
      isActive: false,
      slug: 'receitas-alterar-datas',
    },
    {
      icon: lineTrash,
      description: 'Excluir',
      isActive: false,
      slug: 'receitas-excluir',
    },
    {
      icon: lineSync,
      description: 'Marcar como não pago',
      isActive: false,
      slug: 'receitas-marcar-como-nao-pago',
    },
    {
      icon: lineEye,
      description: 'Visualizar de todos os usuários',
      isActive: false,
      slug: 'receitas-visualizar-todos-usuarios',
    },
  ],
  despesas: [
    {
      icon: lineCoinsDown,
      description: 'Despesas',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: linePlusCircle,
      description: 'Cadastrar',
      isActive: false,
      slug: 'despesas-cadastrar',
    },
    {
      icon: lineEdit,
      description: 'Editar',
      isActive: false,
      slug: 'despesas-editar',
    },
    {
      icon: lineHistory,
      description: 'Alterar datas',
      isActive: false,
      slug: 'despesas-alterar-datas',
    },
    {
      icon: lineCheck,
      description: 'Marcar como pago',
      isActive: false,
      slug: 'despesas-marcar-como-pago',
    },
    {
      icon: lineTrash,
      description: 'Excluir',
      isActive: false,
      slug: 'despesas-excluir',
    },
    {
      icon: lineSync,
      description: 'Marcar como não pago',
      isActive: false,
      slug: 'despesas-marcar-como-nao-pago',
    },
    {
      icon: lineEye,
      description: 'Visualizar de todos os usuários',
      isActive: false,
      slug: 'despesas-visualizar-todos-usuarios',
    },
  ],
  despesasVeiculos: [
    {
      icon: lineCar,
      description: 'Despesas de veiculos',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: linePlusCircle,
      description: 'Cadastrar',
      isActive: false,
      slug: 'despesas-veiculos-cadastrar',
    },
    {
      icon: lineEdit,
      description: 'Editar',
      isActive: false,
      slug: 'despesas-veiculos-editar',
    },
    {
      icon: lineTrash,
      description: 'Excluir',
      isActive: false,
      slug: 'despesas-veiculos-excluir',
    },
    {
      icon: lineEye,
      description: 'Visualizar de todos os usuários',
      isActive: false,
      slug: 'despesas-veiculos-visualizar-todos-usuarios',
    },
  ],
  categorias: [
    {
      icon: lineFileDoublePlus,
      description: 'categorias',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: linePlusCircle,
      description: 'Cadastrar',
      isActive: false,
      slug: 'categorias-cadastrar',
    },
    {
      icon: lineEdit,
      description: 'Editar',
      isActive: false,
      slug: 'categorias-editar',
    },
    {
      icon: lineTrash,
      description: 'Excluir',
      isActive: false,
      slug: 'categorias-excluir',
    },
  ],
  contas: [
    {
      icon: lineFileContract,
      description: 'Contas',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: linePlusCircle,
      description: 'Cadastrar',
      isActive: false,
      slug: 'contas-cadastrar',
    },
    {
      icon: lineEdit,
      description: 'Editar',
      isActive: false,
      slug: 'contas-editar',
    },
    {
      icon: lineTrash,
      description: 'Excluir',
      isActive: false,
      slug: 'contas-excluir',
    },
    {
      icon: lineExchange,
      description: 'Transferência',
      isActive: false,
      slug: 'contas-transferencia',
    },
  ],
  aplicativo: [
    {
      icon: lineMobile,
      description: 'Aplicativo Instrutor',
      isActive: false,
      slug: 'todos',
    },
    {
      icon: lineCheck,
      description: 'Marcar presença',
      isActive: false,
      slug: 'aplicativo-marcar-presenca',
    },
    {
      icon: lineUserSlash,
      description: 'Marcar ausência',
      isActive: false,
      slug: 'aplicativo-marcar-ausencia',
    },
    {
      icon: linePhone,
      description: 'Contato com aluno',
      isActive: false,
      slug: 'aplicativo-contato-com-aluno',
    },
    {
      icon: lineBarcodeRead,
      description: 'Cadastro de despesa de veículos',
      isActive: false,
      slug: 'aplicativo-cadastrar-despesa',
    },
    {
      icon: lineDirection,
      description: 'Exames',
      isActive: false,
      slug: 'aplicativo-exames',
    },

    {
      icon: lineFileInvoice,
      description: 'Relatórios',
      isActive: false,
      slug: 'aplicativo-relatorios',
    },
  ],
};

export const freshChat = async (props: any, close?: boolean) => {
  let restoreID: string = getNestedObject(props, ['profile', 'freshchat_id']);
  //@ts-ignore
  window.fcWidget?.init({
    token: '7cabdbae-001d-4120-9d43-2ee169ba132b',
    host: 'https://wchat.freshchat.com',
    locale: 'pt-BR',
    config: {
      headerProperty: {
        hideChatButton: true,
      },
    },
    externalId: `${getNestedObject(props, [
      'profile',
      'razao_social',
    ])} - ${getNestedObject(props, ['profile', 'emp'])}`,
    firstName: getNestedObject(props, ['profile', 'nome']),
    restoreId: getNestedObject(props, ['profile', 'freshchat_id']),
    email: getNestedObject(props, ['profile', 'email']),
    siteId: getNestedObject(props, ['profile', 'razao_social']),
    empresa: getNestedObject(props, ['profile', 'razao_social']),
    tipo: getTipoUsuario(getNestedObject(props, ['profile', 'tipo']))?.label,
  });

  //@ts-ignore
  window.fcWidget?.user?.get(function (resp) {
    var status = resp && resp.status,
      data = resp && resp.data;
    if (status !== 200) {
      //@ts-ignore
      window.fcWidget.user.setProperties({
        externalId: `${getNestedObject(props, [
          'profile',
          'razao_social',
        ])} - Empresa ${getNestedObject(props, ['profile', 'emp'])}`,
        restoreId: restoreID,
        firstName: getNestedObject(props, ['profile', 'nome']),
        email: getNestedObject(props, ['profile', 'email']),
        siteId: getNestedObject(props, ['profile', 'razao_social']),
        empresa: getNestedObject(props, ['profile', 'razao_social']),
        tipo: getTipoUsuario(getNestedObject(props, ['profile', 'tipo']))?.label,
      });
      //@ts-ignore
      window.fcWidget?.on('user:created', async function (resp) {
        var status = resp && resp.status,
          data = resp && resp.data;
        if (status === 200) {
          if (data.restoreId) {
            const result = await api.setRestoreId(data.restoreId);
            if (result.errors || result.authErrors || result.error || result.erro) {
              props.handleErrors(result);
            } else {
              props.fetchTokenData(result.data.token);
            }
          }
        }
      });
    }
  });

  if (close) {
    return;
  }

  //@ts-ignore
  // window.fcWidget?.on('message:sent', async function (resp) {
  //   console.log('message', resp);
  // });

  //@ts-ignore
  window.fcWidget?.open();
  //@ts-ignore
  window.fcWidget?.show();

  tagHotjar('Solicitou atendimento via freshchat');
};
