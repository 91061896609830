import { create } from "zustand";

interface ExtensaoStore {
    id: string

    setId: (id: string) => void
}
  
const useExtensaoStore = create<ExtensaoStore>((set) => ({
    id: '',
    setId: (id: string) => set({ id }),
}));

export default useExtensaoStore