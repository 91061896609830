import {
  LoginPayload,
  ForgotPassword,
  ResetPassword,
  UpdatePassword,
} from './types';

const actions = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  FETCH_TOKEN_REQUEST: 'FETCH_TOKEN_REQUEST',
  FETCH_TOKEN_SUCCESS: 'FETCH_TOKEN_SUCCESS',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  SET_COOKIE_NAME: 'SET_COOKIE_NAME',
  REDIRECT_WITH_TOKEN_REQUEST: 'REDIRECT_WITH_TOKEN_REQUEST',
  REDIRECT_WITH_TOKEN_SUCCESS: 'REDIRECT_WITH_TOKEN_SUCCESS',
  FETCH_COMPANIES_REQUEST: 'FETCH_COMPANIES_REQUEST',
  FETCH_COMPANIES_SUCCESS: 'FETCH_COMPANIES_SUCCESS',
  CHANGE_COMPANY_REQUEST: 'CHANGE_COMPANY_REQUEST',
  UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  SET_PRIMEIRO_ACESSO: 'SET_PRIMEIRO_ACESSO',
  COMPLETE_REGISTRATION_REQUEST: 'COMPLETE_REGISTRATION_REQUEST',
  COMPLETE_REGISTRATION_SUCCESS: 'COMPLETE_REGISTRATION_SUCCESS',
  VERIFY_TOKEN_COMPLETE_REGISTRATION_REQUEST:
    'VERIFY_TOKEN_COMPLETE_REGISTRATION_REQUEST',
  VERIFY_TOKEN_COMPLETE_REGISTRATION_SUCCESS:
    'VERIFY_TOKEN_COMPLETE_REGISTRATION_SUCCESS',

  login: (payload: LoginPayload) => ({
    type: actions.LOGIN_REQUEST,
    payload,
  }),

  logout: () => ({
    type: actions.LOGOUT_REQUEST,
  }),

  redirectWithToken: (payload: string, ctx: any) => ({
    type: actions.REDIRECT_WITH_TOKEN_REQUEST,
    payload,
    ctx,
  }),

  verifyTokenCompleteRegistration: (payload: string) => ({
    type: actions.VERIFY_TOKEN_COMPLETE_REGISTRATION_REQUEST,
    payload,
  }),

  completeRegistration: (payload: any) => ({
    type: actions.COMPLETE_REGISTRATION_REQUEST,
    payload,
  }),

  fetchTokenData: (payload: string) => ({
    type: actions.FETCH_TOKEN_REQUEST,
    payload,
  }),

  setCookieName: (payload: string) => ({
    type: actions.SET_COOKIE_NAME,
    payload,
  }),

  forgotPassword: (payload: ForgotPassword, callback?: () => void) => ({
    type: actions.FORGOT_PASSWORD_REQUEST,
    payload,
    callback,
  }),

  updatePassword: (payload: UpdatePassword, callback?: () => void) => ({
    type: actions.UPDATE_PASSWORD_REQUEST,
    payload,
    callback,
  }),

  resetPassword: (payload: ResetPassword) => ({
    type: actions.RESET_PASSWORD_REQUEST,
    payload,
  }),

  changeCompany: (payload: any) => ({
    type: actions.CHANGE_COMPANY_REQUEST,
    payload,
  }),

  fetchCompanies: (payload: String) => ({
    type: actions.FETCH_COMPANIES_REQUEST,
    payload,
  }),

  setPrimeiroAcc: (payload: boolean) => ({
    type: actions.SET_PRIMEIRO_ACESSO,
    payload,
  }),
};
export default actions;
