import { SnackbarPayload } from './types.d';

const actions = {
  IS_LOADING: 'IS_LOADING',
  TOGGLE_SNACKBAR: 'TOGGLE_SNACKBAR',
  HANDLE_ERRORS: 'HANDLE_ERRORS',
  HANDLE_SUCCESS: 'HANDLE_SUCCESS',
  TOGGLE_DRAWER: 'TOGGLE_DRAWER',
  TOGGLE_NEWS: 'TOGGLE_NEWS',
  SET_EXTENSION: 'SET_EXTENSION',
  GET_CONTRACT_DAY: 'GET_CONTRACT_DAY',
  GET_CONTRACT_DAY_SUCCESS: 'GET_CONTRACT_DAY_SUCCESS',
  CONTRACT_BUTTON: 'CONTRACT_BUTTON',
  CONTRACT_BUTTON_SUCCESS: 'CONTRACT_BUTTON_SUCCESS',
  CONTRACT_UNDERSTAND: 'CONTRACT_UNDERSTAND',
  SET_NPS: 'SET_NPS',
  SET_NPS_REQUEST: 'SET_NPS_REQUEST',
  SET_NPS_SUCCESS: 'SET_NPS_SUCCESS',
  GET_NEWS_REQUEST: 'GET_NEWS_REQUEST',
  GET_NEWS_SUCCESS: 'GET_NEWS_SUCCESS',
  GET_CONFIG_REQUEST: 'GET_CONFIG_REQUEST',
  GET_CONFIG_SUCCESS: 'GET_CONFIG_SUCCESS',
  GET_RESOURCES_REQUEST: 'GET_RESOURCES_REQUEST',
  GET_RESOURCES_SUCCESS: 'GET_RESOURCES_SUCCESS',
  FETCH_COBRANCAS_REQUEST: 'FETCH_COBRANCAS_REQUEST',
  FETCH_COBRANCAS_SUCCESS: 'FETCH_COBRANCAS_SUCCESS',

  toggleSnackBar: (notification: SnackbarPayload) => ({
    type: actions.TOGGLE_SNACKBAR,
    payload: notification,
  }),

  setLoading: (loading: boolean) => ({
    type: actions.IS_LOADING,
    payload: loading,
  }),

  handleErrors: (err: any) => ({
    type: actions.HANDLE_ERRORS,
    payload: err,
  }),

  handleSuccess: (messages: any) => ({
    type: actions.HANDLE_SUCCESS,
    payload: messages,
  }),

  toggleDrawer: (drawer: boolean) => ({
    type: actions.TOGGLE_DRAWER,
    payload: drawer,
  }),

  toggleNews: (news: boolean) => ({
    type: actions.TOGGLE_NEWS,
    payload: news,
  }),

  setExtension: (extension: boolean) => ({
    type: actions.SET_EXTENSION,
    payload: extension,
  }),

  getNews: () => ({
    type: actions.GET_NEWS_REQUEST,
  }),

  getContractDay: () => ({
    type: actions.GET_CONTRACT_DAY,
  }),

  getConfiguration: () => ({
    type: actions.GET_CONFIG_REQUEST,
  }),

  fetchCobrancas: () => ({
    type: actions.FETCH_COBRANCAS_REQUEST,
  }),

  getResources: () => ({
    type: actions.GET_RESOURCES_REQUEST,
  }),

  contractUnderstand: () => ({
    type: actions.CONTRACT_UNDERSTAND,
  }),

  setNPS: (NPS: boolean) => ({
    type: actions.SET_NPS,
    payload: NPS,
  }),

  getNPS: () => ({
    type: actions.SET_NPS_REQUEST,
  }),
};
export default actions;
