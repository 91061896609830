//NEXT
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const { API_URL } = publicRuntimeConfig;

const apiUrl = String(API_URL);
const icfc = '/icfc';
const paginacfc = '/paginacfc';

const isProduction = process.env.NODE_ENV === 'production';

const siteConfig = {
  siteName: 'iCFC',
  siteIcon: '',
  logo: '',
  logoWhite: '',
  dashboard: '/visao-geral',
  footerText:
    '© copyright' +
    ' ' +
    new Date().getFullYear() +
    ' ' +
    'iCFC. Todos os direitos reservados.',
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};

const language = {
  languageId: 'portuguese',
  locale: 'pt',
  text: 'Português',
};

const langDir = 'ltr';

const apiUrls = {
  //LOGIN
  login: apiUrl + paginacfc + '/auth/login',
  logout: apiUrl + icfc + '/auth/logout',
  verificar: apiUrl + icfc + '/auth/token/verify',
  permissao: apiUrl + icfc + '/permissoes/listar',
  userCompanies: apiUrl + icfc + '/users-companies',
  changeCompany: apiUrl + icfc + '/company/change',
  recommendation: apiUrl + icfc + '/indiqueeganhe',
  signup: apiUrl + '/signup',

  //RECOVER PASSWORD
  create: apiUrl + paginacfc + '/auth/password/create',
  resetPassword: apiUrl + paginacfc + '/auth/password/reset',
  verifyResetPasswordToken: apiUrl + paginacfc + '/auth/password/find',

  //PESSOA
  getPessoa: apiUrl + icfc + '/pessoa',
  insertPessoa: apiUrl + icfc + '/pessoa',
  importStudent: apiUrl + icfc + '/pessoa/importar',
  patchPessoa: apiUrl + icfc + '/pessoa/telefone',
  patchObservacao: apiUrl + icfc + '/pessoa/observacao',
  DownloadStudentsDetail: apiUrl + icfc + '/pessoa/detalhamento',
  deletePessoa: apiUrl + icfc + '/pessoa',
  updatePessoa: apiUrl + icfc + '/pessoa',
  getPessoas: apiUrl + icfc + '/pessoa/filtro',
  selectPessoa: apiUrl + icfc + '/selectpessoa',
  insertFoto: apiUrl + icfc + '/pessoa/imagem',
  getCities: apiUrl + icfc + '/cities',
  getCountries: apiUrl + icfc + '/paises',
  getCep: apiUrl + icfc + '/cep',
  bairro: apiUrl + icfc + '/bairros',

  //PROCESSO
  getProcessos: apiUrl + icfc + '/processo',
  getServicesList: apiUrl + icfc + '/servicos/listar',

  //ALUNO
  getCNHS: apiUrl + icfc + '/aluno/cnh',
  getTaxes: apiUrl + icfc + '/aluno/taxas',
  getAlunos: apiUrl + icfc + '/listalunos',
  getFilesAluno: apiUrl + icfc + '/aluno/documentos',
  deleteFile: apiUrl + icfc + '/aluno/documento',
  viewFile: apiUrl + icfc + '/aluno/documento',
  getFinanceiroAluno: apiUrl + icfc + '/aluno/vendas',
  getAlunoIntegracao: apiUrl + icfc + '/aluno/dadosintegracao',
  insertCNH: apiUrl + icfc + '/aluno/cnh',
  updateCNH: apiUrl + icfc + '/aluno/cnh',
  deleteCNH: apiUrl + icfc + '/aluno/cnh',
  fetchStudentSchedule: apiUrl + icfc + '/agenda/visualizar',
  fetchStudentScheduleNew: apiUrl + icfc + '/agenda-pratica',
  getVeiculosInstrutores: apiUrl + icfc + '/agenda/filtros',
  fetchHistoryClasses: apiUrl + icfc + '/agenda/historico',
  insertClass: apiUrl + icfc + '/agenda/agendar',
  updateClass: apiUrl + icfc + '/agenda/alterarhorario',
  deleteClass: apiUrl + icfc + '/agenda/alterarsituacao',
  confirmClass: apiUrl + icfc + '/agenda/alterarsituacao',
  updateObservation: apiUrl + icfc + '/agenda/alterarobservacao',
  buscarHorariosAgendamentoMultiplo:
    apiUrl + icfc + '/agenda/buscar-horario-agendamento-multiplo',
  agendamentoMultiplo: apiUrl + icfc + '/agenda/agendamento-multiplo',

  //VENDAS
  getFormaPagamento: apiUrl + icfc + '/condicaopagamento',
  getAumentoDesconto: apiUrl + icfc + '/condicaopagamento',
  getParcelas: apiUrl + icfc + '/numeroparcelas',
  removeProcess: apiUrl + icfc + '/venda',
  insertVenda: apiUrl + icfc + '/venda',
  getVenda: apiUrl + icfc + '/venda',
  getVendaPersonalizada: apiUrl + icfc + '/venda/info',
  getVendaRapida: apiUrl + icfc + '/vendarapida',
  insertVendaRapida: apiUrl + icfc + '/vendarapida',
  insertRenach: apiUrl + icfc + '/venda/renach',
  gerarContrato: apiUrl + icfc + '/venda/gerarcontrato',
  gerarFile: apiUrl + icfc + '/impressao',
  getDadosRenach: apiUrl + icfc + '/venda/renach',
  getDadosRenachAluno: apiUrl + icfc + '/aluno/renach',
  getProcesso: apiUrl + icfc + '/venda/processo',
  completeProcess: apiUrl + icfc + '/venda/encerrar',
  reabrirProcess: apiUrl + icfc + '/venda/reabrir',
  aprovar: apiUrl + icfc + '/venda/aprovar',
  DownloadSalesDetail: apiUrl + icfc + '/venda/detalhamento',
  fetchServicePaymentConditions: apiUrl + icfc + '/venda/condicaopagamento/servico',
  insertSaleService: apiUrl + icfc + '/venda/servico',
  fetchServiceDetail: apiUrl + icfc + '/venda/servicodetalhar',
  closeInstallments: apiUrl + icfc + '/venda/parcelas/encerrar',
  relatoriosServicosVendidos: apiUrl + icfc + '/venda/relatorios/servicos-vendidos',
  verificarTaxaConfigurada: apiUrl + icfc + '/financeiro/verificar-taxa-configurada',

  //PARCELAS
  infoInstallments: apiUrl + icfc + '/parcela',
  payInstallments: apiUrl + icfc + '/parcela/baixar',
  returnedInstallments: apiUrl + icfc + '/parcela/devolver',
  dividerInstallments: apiUrl + icfc + '/parcela/dividir',
  reopenInstallments: apiUrl + icfc + '/parcela/reabrir',
  reopenInstallmentsStatement: apiUrl + icfc + '/parcela/extrato/reabrir',
  deleteInstallments: apiUrl + icfc + '/parcela/excluir',
  gerarpromissoria: apiUrl + icfc + '/parcela/gerarpromissoria',
  gerarCobranca: apiUrl + icfc + '/parcela/gerarcobranca',
  gerarCarne: apiUrl + icfc + '/parcela/gerarcarne',
  gerarRecibo: apiUrl + icfc + '/parcela/gerarrecibo',
  verMovimentacao: apiUrl + icfc + '/parcela/movimentacoes',

  //LEADS
  getLeads: apiUrl + icfc + '/precadastro',
  insertContactLead: apiUrl + icfc + '/contato',
  getContatoLead: apiUrl + icfc + '/contato',
  loseLead: apiUrl + icfc + '/precadastro/perder',
  insertLead: apiUrl + icfc + '/precadastro',
  deleteLead: apiUrl + icfc + '/precadastro',

  //FINANCEIRO
  fetchListStatement: apiUrl + icfc + '/financeiro',
  fetchAccounts: apiUrl + '/financeiro/contas',
  fetchExtrato: apiUrl + '/financeiro/extrato',
  downloadExtrato: apiUrl + '/financeiro/extrato/xlsx',
  DownloadAccountsDetail: apiUrl + icfc + '/financeiro/extrato-conta/exportar-xlsx',
  DownloadStatementDetail: apiUrl + icfc + '/financeiro/detalhamento',
  DownloadIncomeDetail: apiUrl + icfc + '/financeiro/receita/detalhamento',
  DownloadExpensesDetail: apiUrl + icfc + '/financeiro/despesa/detalhamento',
  insertExpense: apiUrl + icfc + '/despesa',
  deleteExpense: apiUrl + icfc + '/despesa',
  fetchListarFormasCobranca:
    apiUrl + icfc + '/financeiro/listar-formas-cobrancas-configuradas',

  //DASHBOARD
  onboarding: apiUrl + icfc + '/onboarding',
  fetchConfig: apiUrl + icfc + '/empresa/config',
  contractDay: apiUrl + icfc + '/empresa/datacontratacao',
  contractCompany: apiUrl + icfc + '/meus-dados/converter-teste-gratis',
  gerarFaturaPrePago: apiUrl + icfc + '/meus-dados/gerar-fatura-prepago',
  fetchDashboardData: apiUrl + icfc + '/dashboard',
  DownloadDetail: apiUrl + icfc + '/dashboard/detalhamento',
  getNews: apiUrl + icfc + '/novidades',

  //COMPENSAÇÃO
  compensacao: apiUrl + icfc + '/empresa/taxas',

  //CONFIGURAÇÕES
  getCompany: apiUrl + icfc + '/empresa',
  fetchPaymentConditions: apiUrl + icfc + '/empresa/condicaopagamento',
  fetchObservation: apiUrl + icfc + '/empresa/observacoes-financeiro',
  fetchCredentials: apiUrl + icfc + '/empresa/tokens',
  getListCharges: apiUrl + icfc + '/cobrancas',
  boleto: apiUrl + icfc + '/boleto',
  getNFSe: apiUrl + icfc + '/meus-dados/cobrancas/baixar-nfse',
  updateCompany: apiUrl + icfc + '/empresa',
  fetchPageData: apiUrl + icfc + '/info',
  updatePageConfig: apiUrl + icfc + '/info',
  getLocation: apiUrl + icfc + '/geolocation',
  fetchListContracts: apiUrl + icfc + '/impressao',
  getContract: apiUrl + icfc + '/impressao',
  insertContract: apiUrl + icfc + '/impressao',
  deleteContract: apiUrl + icfc + '/impressao',
  fetchListPrints: apiUrl + icfc + '/impressao',
  getPrint: apiUrl + icfc + '/impressao',
  insertPrint: apiUrl + icfc + '/impressao',
  deletePrint: apiUrl + icfc + '/impressao',
  fetchTokensList: apiUrl + icfc + '/tokenintegracao',
  insertNewToken: apiUrl + icfc + '/tokenintegracao',
  deleteToken: apiUrl + icfc + '/tokenintegracao',

  //VEICULOS
  fetchListVehicles: apiUrl + icfc + '/equipamento',
  fetchVehicle: apiUrl + icfc + '/equipamento',
  insertVehicle: apiUrl + icfc + '/equipamento',
  updateVehicle: apiUrl + icfc + '/equipamento',
  deleteVehicle: apiUrl + icfc + '/equipamento',

  //PERIODO
  fetchListPeriods: apiUrl + icfc + '/periodo',
  fetchPeriod: apiUrl + icfc + '/periodo',
  insertPeriod: apiUrl + icfc + '/periodo',
  updatePeriod: apiUrl + icfc + '/periodo',
  deletePeriod: apiUrl + icfc + '/periodo',
  fetchInstructors: apiUrl + icfc + '/instrutor',
  fetchVehicles: apiUrl + icfc + '/equipamento',

  //AGENDA
  fetchSchedule: apiUrl + icfc + '/agenda/configuracao',
  insertSchedule: apiUrl + icfc + '/agenda/configuracao',
  updateSchedule: apiUrl + icfc + '/agenda/configuracao',
  deleteSchedule: apiUrl + icfc + '/agenda/configuracao',
  printSchedule: apiUrl + icfc + '/agenda/imprimir',
  printScheduleHistory: apiUrl + icfc + '/agenda/historico/imprimir',
  fetchConfigSchedule: apiUrl + icfc + '/agenda/configuracaogeral',
  categoriesSchedule: apiUrl + icfc + '/agenda/categorias',
  totalizadorAulas: apiUrl + icfc + '/agenda-pratica',
  instrutoresEveiculos: apiUrl + icfc + '/agenda-pratica/instrutores-veiculos',

  //TRANSFERÊNCIA
  fetchTransfer: apiUrl + icfc + '/agenda/transferencia/aulas',
  filtersTransfer: apiUrl + icfc + '/agenda/transferencia/filtros',

  //EXAME
  fetchListVehiclesInstructor: apiUrl + icfc + '/exame/listarinstrutoreseveiculos',

  getVehicleInstructorStudents:
    apiUrl + icfc + '/exame/listarinstrutoreseveiculosalunos',
  validateExam: apiUrl + icfc + '/exame/validarhorarios',
  fetchExamSchedule: apiUrl + icfc + '/exame',
  validateHoursExam: apiUrl + icfc + '/exame/validarhorarios',
  deleteExam: apiUrl + icfc + '/exame',
  updateExam: apiUrl + icfc + '/exame',
  getExamData: apiUrl + icfc + '/exame/dados',
  printExam: apiUrl + icfc + '/exame/imprimir',
  configExam: apiUrl + icfc + '/exame/configuracoes',

  //INSTRUTOR
  fetchInstructorSchedule: apiUrl + icfc + '/agendaPratica/instrutor',
  fetchStudentVehicles: apiUrl + icfc + '/agendaPratica/filtros',
  printScheduleInstructor: apiUrl + icfc + '/agendaPratica/instrutor/imprimir',
  getVehiclesByInstructor: apiUrl + icfc + '/agendaPratica/instrutor/filtros',
  fetchAulasPraticasMesmoHorario:
    apiUrl + icfc + '/agendaPratica/aulas-mesmo-horario',

  //USERS
  getUsers: apiUrl + icfc + '/usuario',
  getUserData: apiUrl + icfc + '/usuario',
  insertUser: apiUrl + icfc + '/usuario',
  updateUser: apiUrl + icfc + '/usuario',
  deleteUser: apiUrl + icfc + '/usuario',
  updatePassword: apiUrl + icfc + '/usuario/password',
  getEmployees: apiUrl + icfc + '/selectpessoa',
  getEmployee: apiUrl + icfc + '/pessoa',
  insertEmployee: apiUrl + icfc + '/pessoa',
  updateEmployee: apiUrl + icfc + '/pessoa',
  deleteEmployee: apiUrl + icfc + '/pessoa',
  insertProvider: apiUrl + icfc + '/pessoa',
  fetchEmployees: apiUrl + icfc + '/listcolaboradores',
  fetchProviders: apiUrl + icfc + '/selectpessoa',
  fetchProccess: apiUrl + icfc + '/listprocesso',
  getProccess: apiUrl + icfc + '/processo',
  insertProccess: apiUrl + icfc + '/processo',
  updateProccess: apiUrl + icfc + '/processo',
  deleteProccess: apiUrl + icfc + '/processo',
  getTipo: apiUrl + icfc + '/tipohabilitacao',
  fetchServices: apiUrl + icfc + '/tipohabilitacao',

  //FRESHCHAT
  setRestoreId: apiUrl + icfc + '/usuario/storefreshchatid',

  //GUIAS
  imitirGuia: apiUrl + icfc + '/emitirguia',

  //NPS
  sendNPS: apiUrl + icfc + '/nps',
  getNPS: apiUrl + icfc + '/nps',

  //SERVICOS
  fetchListServices: apiUrl + icfc + '/servico',
  fetchService: apiUrl + icfc + '/servico',
  insertService: apiUrl + icfc + '/servico',
  updateService: apiUrl + icfc + '/servico',
  deleteService: apiUrl + icfc + '/servico',
  inactiveService: apiUrl + icfc + '/servico',
  fetchTypeService: apiUrl + icfc + '/tiposervico',

  //PROTOCOLOS
  fetchListModelProtocols: apiUrl + icfc + '/modelos-protocolos',
  deleteModelsProtocols: apiUrl + icfc + '/modelos-protocolos',
  insertModelsProtocols: apiUrl + icfc + '/modelos-protocolos',
  updateModelsProtocols: apiUrl + icfc + '/modelos-protocolos',
  fetchListProcolos: apiUrl + icfc + '/protocolos',
  updateProtocolData: apiUrl + icfc + '/protocolos',
  fetchProtocolData: apiUrl + icfc + '/protocolos',
  gerarProtocolo: apiUrl + icfc + '/protocolos',

  //INTEGRAÇÕES
  integration: apiUrl + icfc + '/integracoes',
  integrationSchedule: apiUrl + icfc + '/agenda/integracoes',
  integrationSchedulePassword: apiUrl + icfc + '/agenda/integracoes/senha',

  //PERMISSÕES
  permissions: apiUrl + icfc + '/permissoes/config',
  fetchPermissions: apiUrl + icfc + '/permissoes',

  //HISTÓRICO
  filtersHistory: apiUrl + icfc + '/historico/filtros',
  fetchHistory: apiUrl + icfc + '/historico',

  //DESPESA VEICULOS
  expensesVehicles: apiUrl + icfc + '/despesas-veiculos',
  expensesVehiclesFilters: apiUrl + icfc + '/despesas-veiculos/filtros',
  fetchProdutosServicos: apiUrl + icfc + '/despesas-veiculos/produtos-servicos',

  //RECURSOS
  fetchResources: apiUrl + icfc + '/meus-dados/recursos-contratados',

  //COBRANCAS
  fetchCobrancas: apiUrl + '/meus-dados/cobrancas',
};

export { siteConfig, themeConfig, language, langDir, apiUrls, isProduction };
