import { ContentObject } from '@l_ine/typed';

export const states: {
  [name: string]: ContentObject & { name: string };
} = {
  '11': {
    key: 11,
    value: 'RO',
    label: 'RO',
    name: 'Rondonia',
  },
  '12': {
    key: 12,
    value: 'AC',
    label: 'AC',
    name: 'Acre',
  },
  '13': {
    key: 13,
    value: 'AM',
    label: 'AM',
    name: 'Amazonas',
  },
  '14': {
    key: 14,
    value: 'RR',
    label: 'RR',
    name: 'Roraima',
  },
  '15': {
    key: 15,
    value: 'PA',
    label: 'PA',
    name: 'Para',
  },
  '16': {
    key: 16,
    value: 'AP',
    label: 'AP',
    name: 'Amapa',
  },
  '17': {
    key: 17,
    value: 'TO',
    label: 'TO',
    name: 'Tocantins',
  },
  '21': {
    key: 21,
    value: 'MA',
    label: 'MA',
    name: 'Maranhao',
  },
  '22': {
    key: 22,
    value: 'PI',
    label: 'PI',
    name: 'Piaui',
  },
  '23': {
    key: 23,
    value: 'CE',
    label: 'CE',
    name: 'Ceara',
  },
  '24': {
    key: 24,
    value: 'RN',
    label: 'RN',
    name: 'Rio Grande do Norte',
  },
  '25': {
    key: 25,
    value: 'PB',
    label: 'PB',
    name: 'Paraiba',
  },
  '26': {
    key: 26,
    value: 'PE',
    label: 'PE',
    name: 'Pernambuco',
  },
  '27': {
    key: 27,
    value: 'AL',
    label: 'AL',
    name: 'Alagoas',
  },
  '28': {
    key: 28,
    value: 'SE',
    label: 'SE',
    name: 'Sergipe',
  },
  '29': {
    key: 29,
    value: 'BA',
    label: 'BA',
    name: 'Bahia',
  },
  '31': {
    key: 31,
    value: 'MG',
    label: 'MG',
    name: 'Minas Gerais',
  },
  '32': {
    key: 32,
    value: 'ES',
    label: 'ES',
    name: 'Espirito Santo',
  },
  '33': {
    key: 33,
    value: 'RJ',
    label: 'RJ',
    name: 'Rio de Janeiro',
  },
  '35': {
    key: 35,
    value: 'SP',
    label: 'SP',
    name: 'Sao Paulo',
  },
  '41': {
    key: 41,
    value: 'PR',
    label: 'PR',
    name: 'Parana',
  },
  '42': {
    key: 42,
    value: 'SC',
    label: 'SC',
    name: 'Santa Catarina',
  },
  '43': {
    key: 43,
    value: 'RS',
    label: 'RS',
    name: 'Rio Grande do Sul',
  },
  '50': {
    key: 50,
    value: 'MS',
    label: 'MS',
    name: 'Mato Grosso do Sul',
  },
  '51': {
    key: 51,
    value: 'MT',
    label: 'MT',
    name: 'Mato Grosso',
  },
  '52': {
    key: 52,
    value: 'GO',
    label: 'GO',
    name: 'Goias',
  },
  '53': {
    key: 53,
    value: 'DF',
    label: 'DF',
    name: 'Distrito Federal',
  },
  '99': {
    key: 99,
    value: 'EX',
    label: 'EX',
    name: 'Exterior',
  },
};
export default states;
